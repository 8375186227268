import { getRangeValue, rangeColorToHsl } from "tsparticles-engine";
import { Roll } from "./Options/Classes/Roll";
function updateRoll(particle, delta) {
    const roll = particle.options.roll;
    if (!particle.roll || !(roll === null || roll === void 0 ? void 0 : roll.enable)) {
        return;
    }
    const speed = particle.roll.speed * delta.factor, max = 2 * Math.PI;
    particle.roll.angle += speed;
    if (particle.roll.angle > max) {
        particle.roll.angle -= max;
    }
}
export class RollUpdater {
    init(particle) {
        const rollOpt = particle.options.roll;
        if (rollOpt === null || rollOpt === void 0 ? void 0 : rollOpt.enable) {
            particle.roll = {
                enable: rollOpt.enable,
                horizontal: rollOpt.mode === "horizontal" || rollOpt.mode === "both",
                vertical: rollOpt.mode === "vertical" || rollOpt.mode === "both",
                angle: Math.random() * Math.PI * 2,
                speed: getRangeValue(rollOpt.speed) / 360,
            };
            if (rollOpt.backColor) {
                particle.backColor = rangeColorToHsl(rollOpt.backColor);
            }
            else if (rollOpt.darken.enable && rollOpt.enlighten.enable) {
                const alterType = Math.random() >= 0.5 ? "darken" : "enlighten";
                particle.roll.alter = {
                    type: alterType,
                    value: getRangeValue(alterType === "darken" ? rollOpt.darken.value : rollOpt.enlighten.value),
                };
            }
            else if (rollOpt.darken.enable) {
                particle.roll.alter = {
                    type: "darken",
                    value: getRangeValue(rollOpt.darken.value),
                };
            }
            else if (rollOpt.enlighten.enable) {
                particle.roll.alter = {
                    type: "enlighten",
                    value: getRangeValue(rollOpt.enlighten.value),
                };
            }
        }
        else {
            particle.roll = {
                enable: false,
                horizontal: false,
                vertical: false,
                angle: 0,
                speed: 0,
            };
        }
    }
    isEnabled(particle) {
        const roll = particle.options.roll;
        return !particle.destroyed && !particle.spawning && !!(roll === null || roll === void 0 ? void 0 : roll.enable);
    }
    update(particle, delta) {
        if (!this.isEnabled(particle)) {
            return;
        }
        updateRoll(particle, delta);
    }
    loadOptions(options, ...sources) {
        for (const source of sources) {
            if (!(source === null || source === void 0 ? void 0 : source.roll)) {
                continue;
            }
            if (!options.roll) {
                options.roll = new Roll();
            }
            options.roll.load(source.roll);
        }
    }
}
