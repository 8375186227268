import { ExternalInteractorBase } from "tsparticles-engine";
export class Remover extends ExternalInteractorBase {
    constructor(container) {
        super(container);
        this.handleClickMode = (mode) => {
            if (mode !== "remove") {
                return;
            }
            const container = this.container, options = container.actualOptions, removeNb = options.interactivity.modes.remove.quantity;
            container.particles.removeQuantity(removeNb);
        };
    }
    isEnabled() {
        return true;
    }
    clear() {
    }
    reset() {
    }
    async interact() {
    }
}
